import React from 'react'
import LogoSVG from 'src/assets/svg/logo.svg';

function Footer() {
    return (
        <div className='flex justify-center  my-20 flex-col'>
            <div className='flex justify-center'>
                <div className='flex flex-row justify-between  w-full max-w-[1000px]'>
                    <div className='flex justify-center items-center'>
                        <img src={LogoSVG} alt="logo" />
                    </div>

                    <div className='flex flex-col text-color-gray-2'>
                        <span className='text-inherit subtitle pb-3'> Pennytots</span>
                        <span className='text-inherit title-2-regular my-1'> Brand</span>
                        <span className='text-inherit title-2-regular my-1'> Admin Platform</span>
                        <span className='text-inherit title-2-regular my-1'> Download</span>
                        <span className='text-inherit title-2-regular my-1'> Messages</span>
                    </div>

                    <div className='flex flex-col text-color-gray-2'>
                        <span className='text-inherit subtitle pb-3'> Company</span>
                        <span className='text-inherit title-2-regular my-1'> About us</span>
                        <span className='text-inherit title-2-regular my-1'> Contact Us</span>
                        <span className='text-inherit title-2-regular my-1'> Messages</span>
                    </div>

                    <div className='flex flex-col text-color-gray-2'>
                        <span className='text-inherit subtitle pb-3'> Download</span>
                        <span className='text-inherit title-2-regular my-1'> Android</span>
                        <span className='text-inherit title-2-regular my-1'> iPhone</span>
                    </div>


                    <div className='flex flex-col text-color-gray-2'>
                        <span className='text-inherit subtitle pb-3'> Others</span>
                        <span className='text-inherit title-2-regular my-1'> Help Desk</span>
                        <span className='text-inherit title-2-regular my-1'>Privacy Policy</span>
                        <span className='text-inherit title-2-regular my-1'>Terms of Use</span>
                    </div>

                </div>
            </div>


            <div className='text-center mt-5'>
                <span className='text-color-gray-2 subtitle pb-3'> All right reserved @ 2022</span>
            </div>
        </div>
    )
}

export default Footer