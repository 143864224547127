import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './pages';
import ResourcesPage from './pages/resources';

function App() {

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/download' element={<LandingPage />} />
          <Route path='/resources' element={<ResourcesPage />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
