import React from 'react';
import { NavLink } from 'react-router-dom';
import LogoSVG from 'src/assets/svg/logo.svg';
import WorldSVG from 'src/assets/svg/world.svg';
import MenuSVG from 'src/assets/svg/menu.svg';

interface AuthLayoutProps {
    children: React.ReactNode;
}

function MainLayout({ children }: AuthLayoutProps) {

    const nav = [
        {
            title: "Home",
            route: '/',
        },
        {
            title: "Download",
            route: '/download',
        },
        {
            title: "Resources",
            route: '/resources',
        },
    ]



    return (
        <React.Fragment>
            <div className='flex flex-col'>

                {/* Desktop */}
                <div className='hidden md:flex flex-row justify-between p-4'>
                    <div>
                        <img src={LogoSVG} alt="" className='w-[100px]' />

                    </div>



                    <div className='flex flex-row' >

                        {nav.map((item, index) => (
                            <NavLink
                                key={index}
                                end
                                to={item.route}
                                className={({ isActive }) =>
                                    isActive ? 'flex flex-row font-bold text-color-black h-[25px] items-center border-b-[3px] border-b-primary pb-2 px-4'
                                        :
                                        'flex flex-row  font-bold text-color-black h-[25px] items-center px-4'
                                }
                            >

                                <span className='text-inherit'> {item.title}</span>
                            </NavLink>
                        ))}

                    </div>



                </div>

                <div className='flex md:hidden flex-row justify-between p-4 w-full'>
                    <div>
                        <img src={WorldSVG} alt="" />
                    </div>
                    <div>
                        <img src={LogoSVG} alt="" className='w-[100px]' />
                    </div>
                    <div>
                        <img src={MenuSVG} alt="" />
                    </div>
                </div>

                <div>
                    {children}
                </div>
            </div>


        </React.Fragment>

    )
}

export default MainLayout;