import React from 'react'
import MainLayout from 'src/layout/main'
import LandingMobileSVG from 'src/assets/svg/landing-mobiles.svg';
import LandingMobileViewSVG from 'src/assets/svg/landing-mobile-view.svg';
import DownloadPennytots from 'src/components/download-pennytots';
import LogoSVG from 'src/assets/svg/logo.svg';
import AppleStoreSVG from 'src/assets/svg/apple-store.svg';
import PlayStoreSVG from 'src/assets/svg/play-store.svg';
import Footer from 'src/components/footer';

function LandingPage() {
    return (
        <MainLayout>
            <div className='flex flex-col  mt-10 md:mt-40'>

                {/* Section 1 */}
                <div className='flex flex-col md:flex-row'>
                    <div className='w-full md:w-1/2 flex flex-col pl-12 justify-between'>
                        <div className='flex flex-col'>
                            <span className='header-3 md:header-1'>
                                Lorem ipsum dolor sit amet
                            </span>
                            <div className='w-full max-w-[500px] text-center md:!text-left'>
                                <span className='title-3-semibold md:sub-header'>
                                    In accumsan sapien eget elit hendrerit, id scelerisque felis hendrerit
                                </span>
                            </div>


                            <div className='mt-3 hidden md:block'>
                                <DownloadPennytots />
                            </div>
                        </div>

                        <hr className='divider hidden md:block' />

                        <div className='border-b-4 border-b-primary pb-4 hidden md:block'>
                            <span className='text-[30px] font-medium text-color-black leading-[50px]'> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean id luctus lectus, sit amet euismod augue</span>
                        </div>
                    </div>


                    <div className='w-full md:w-1/2 flex justify-end'>
                        <div className='hidden md:block'>
                            <img src={LandingMobileSVG} alt="" />
                        </div>

                        <div className='flex md:hidden mt-5  justify-center w-full'>
                            <img src={LandingMobileViewSVG} alt="" />
                        </div>

                    </div>

                    <div className='flex md:hidden flex-row justify-center mt-5'>
                        <DownloadPennytots />
                    </div>


                    <div className='flex md:hidden text-center justify-center mt-5 px-5'>
                        <span className='title-3font-medium text-color-black'> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean id luctus lectus, sit amet euismod augue</span>
                    </div>

                </div>




                {/* Section 2 */}

                <div className='my-[100px] flex flex-col px-5 md:px-0'>
                    <div className='flex items-center flex-col'>
                        <div className='w-[50%]'>
                            <hr className='divider' />
                        </div>

                        <div className='mt-5 hidden md:flex flex-row items-center'>
                            <span className='header-2 !font-normal'>
                                Download the
                            </span>
                            <div className='flex flex-col items-center mt-[11px] ml-4'>
                                <img src={LogoSVG} alt="" />
                            </div>

                            <span className='header-2 !font-normal ml-2'>
                                app
                            </span>
                        </div>

                        <div className='md:w-[500px] text-center mt-5 md:mt-0'>
                            <span className='header-3'>Proin laoreet mauris eget eros dapibus rhoncus. Etiam a imperdiet purus</span>
                        </div>

                        <div className='flex flex-row mt-5'>
                            <div>
                                <img src={AppleStoreSVG} alt="" />
                            </div>
                            <div className='ml-3'>
                                <img src={PlayStoreSVG} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>

        </MainLayout>

    )
}

export default LandingPage;