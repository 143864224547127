import React from 'react'

function DownloadPennytots() {
    return (
        <button className='!bg-primary p-[15px] rounded-lg'>
            <span className='text-3'>  Download Pennytots</span>
        </button>
    )
}

export default DownloadPennytots;